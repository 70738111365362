// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-product-template-js": () => import("/opt/build/repo/src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cortinas-js": () => import("/opt/build/repo/src/pages/cortinas.js" /* webpackChunkName: "component---src-pages-cortinas-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-retapizados-js": () => import("/opt/build/repo/src/pages/retapizados.js" /* webpackChunkName: "component---src-pages-retapizados-js" */)
}

